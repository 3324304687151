/* eslint-disable i18next/no-literal-string */
import React from 'react';
import {
  Form,
  FormGroup,
  FormLabel,
  FormRow,
  FormCol,
  CardContent,
  CardLarge,
  CardSeparator,
  CheckBox,
  Heading3,
  RadioButtonGroup,
  RadioButton,
  RadioButtonLabel,
  CardFooter,
  useValidator,
  Validators,
  ValidationErrorMessage,
  Size,
  useModalState,
  Select,
  ISelectOption
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { DayMonthYear1, MedicareCardColour } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  consentToIdCheckLabel,
  consentToIdCheckLabelANZM,
  defaultMedicare,
  IIdentificationComponentProps,
  IMedicareDetails,
  MEDICARE_CARD_COLOURS
} from '../models/identification';
import { IStore } from '../Store/Store';
import { submitMedicareDetails } from '../Store/Actions';
import { FIELD_REGEX_RULES, FIELD_VALIDATION_MESSAGES } from '../constants/validationConstants';
import { dateValidator } from '../validators/dateValidator';
import { MedicareExpiryDateValidation } from '../models/validation';
import { IdentificationCardFooter } from '../Components/IdentificationCardFooter';
import { IdentificationCardHeader } from '../Components/IdentificationCardHeader';
import { Date } from '../Components/Date';
import { ENABLED_TABINDEX_FOR_NON_INPUT } from '../constants/commonConstants';
import { ifEnterKey } from '../events/onKeyDown';
import { getLastDayOfMonth } from '../utils/dateUtils';
import { useIdValidationErrorMessageModal } from '../hooks/useIdValidationErrorMessageModal';
import { PositiveNumericFormControl } from '../Components/PositiveNumericFormControl';
import { MedicareCardNumberImage, MedicareReferenceNumberImage } from '../Components/IdentificationImageMedicare';
import { filterByStartWith } from '../models/filterOption';
import { buildFullNamesToMatchNameOnCard } from '../models/medicare';
import { AccountTypeInput, IsIndividualAccountType } from '../models/application';

export const IdentificationMedicare: React.FC<IIdentificationComponentProps> = ({ onNext, onGoBack, onTryAgain }) => {
  const medicare = useSelector((store: IStore) => store.remote.identificationDetails.medicare || defaultMedicare);
  const personalDetails = useSelector((store: IStore) => store.remote.profile.personalDetails);
  const nameOptions = buildFullNamesToMatchNameOnCard(personalDetails);
  const accountType = useSelector((store: IStore) => store.local.accountType || AccountTypeInput.Individual);

  const validatorConfig = {
    validated: medicare.validated,
    debounceMs: 0
  };
  const [, setModalState] = useModalState();
  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cardColourValidator = useValidator(medicare.cardColour, Validators.required(t('Required')), validatorConfig);
  const cardNumberValidator = useValidator(
    medicare.cardNumber,
    Validators.required(t('Required')).match(FIELD_REGEX_RULES.MedicareCardNumber.pattern, FIELD_REGEX_RULES.MedicareCardNumber.message(t)),
    validatorConfig
  );
  const referenceNumberValidator = useValidator(
    medicare.referenceNumber,
    Validators.required(t('Required')).match(FIELD_REGEX_RULES.MedicareReferenceNumber.pattern, FIELD_REGEX_RULES.MedicareReferenceNumber.message(t)),
    validatorConfig
  );

  const expiryDateCheckResult = (value: DayMonthYear1) => {
    return dateValidator.checkMedicareExpiryDate({ day: value.day, month: value.month, year: value.year }, cardColourValidator.value);
  };

  const isExpiryDateInvalid = (value: DayMonthYear1) => {
    return expiryDateCheckResult(value) === MedicareExpiryDateValidation.Invalid;
  };

  const isExpiryDateExpired = (value: DayMonthYear1) => {
    return expiryDateCheckResult(value) === MedicareExpiryDateValidation.Expired;
  };

  const isExpiryDateWithin2Months = (value: DayMonthYear1) => {
    return expiryDateCheckResult(value) === MedicareExpiryDateValidation.Within2Months;
  };

  const middleNameValidator = useValidator(
    nameOptions.find((x) => x.value === medicare?.middleName),
    Validators.required(t('Name is required')),
    validatorConfig
  );

  const expiryDateValidator = useValidator<DayMonthYear1>(
    medicare.expiryDate,
    Validators.custom((value: DayMonthYear1) => !isExpiryDateInvalid(value), FIELD_VALIDATION_MESSAGES[MedicareExpiryDateValidation.Invalid].message(t))
      .custom((value: DayMonthYear1) => !isExpiryDateExpired(value), FIELD_VALIDATION_MESSAGES[MedicareExpiryDateValidation.Expired].message(t))
      .custom((value: DayMonthYear1) => !isExpiryDateWithin2Months(value), FIELD_VALIDATION_MESSAGES[MedicareExpiryDateValidation.Within2Months].message(t)),
    validatorConfig
  );

  const consentToIdCheckValidator = useValidator(
    medicare.consentToIdCheck,
    Validators.custom((value) => value, t('Required')),
    validatorConfig
  );

  // TODO when moved to web-design should also add validator handle function similar to
  const onMedicareCardColorChanged = (medicareCardColour: MedicareCardColour) => {
    cardColourValidator.handleEvent(medicareCardColour);
  };

  const validationStatus = useIdValidationErrorMessageModal(setModalState, onNext, onGoBack, onTryAgain);

  const expiryDay =
    cardColourValidator.value === MedicareCardColour.Green
      ? getLastDayOfMonth(expiryDateValidator.value.year, expiryDateValidator.value.month)!
      : expiryDateValidator.value.day;

  const submitMedicareDetailsInternal = () => {
    const medicareDetails: IMedicareDetails = {
      consentToIdCheck: consentToIdCheckValidator.value,
      cardNumber: cardNumberValidator.value,
      expiryDate: {
        day: expiryDay,
        month: expiryDateValidator.value.month,
        year: expiryDateValidator.value.year
      },
      cardColour: cardColourValidator.value,
      referenceNumber: referenceNumberValidator.value,
      middleName: middleNameValidator.value?.value
    };
    dispatch(submitMedicareDetails(medicareDetails));
  };

  return (
    <Form>
      <CardLarge>
        <IdentificationCardHeader onGoBack={!reviewSection ? onTryAgain : undefined} />
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <Heading3>{t('Medicare')}</Heading3>
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Card Colour')}</FormLabel>
            <RadioButtonGroup>
              {Object.keys(MEDICARE_CARD_COLOURS).map((colour) => (
                <React.Fragment key={colour}>
                  <RadioButton
                    name='cardColour'
                    id={colour}
                    value={colour}
                    checked={cardColourValidator.value === colour}
                    onChange={(e) => cardColourValidator.handleEvent(e.target.value as MedicareCardColour)}
                    invalid={cardColourValidator.invalid}
                  />
                  <RadioButtonLabel
                    htmlFor={colour}
                    tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT}
                    onKeyDown={(e) => ifEnterKey(e, () => onMedicareCardColorChanged((e.target as HTMLLabelElement).htmlFor as MedicareCardColour))}
                  >
                    {MEDICARE_CARD_COLOURS[colour](t)}
                  </RadioButtonLabel>
                </React.Fragment>
              ))}
            </RadioButtonGroup>
            <ValidationErrorMessage size={Size.Medium} validator={cardColourValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Card number')}</FormLabel>
            <MedicareCardNumberImage cardColour={cardColourValidator.value as MedicareCardColour} />
            <PositiveNumericFormControl
              placeholder={t('10 digit number')}
              value={cardNumberValidator.value}
              onChange={(e) => cardNumberValidator.handleEvent(e.target.value)}
              onBlur={(e) => cardNumberValidator.handleEvent(e.target.value.trim())}
              invalid={cardNumberValidator.invalid}
            />
            <ValidationErrorMessage size={Size.Medium} validator={cardNumberValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Reference number (next to your name)')}</FormLabel>
            <MedicareReferenceNumberImage cardColour={cardColourValidator.value as MedicareCardColour} />
            <FormRow>
              <FormCol percentWidth={30}>
                <PositiveNumericFormControl
                  textAlign='center'
                  placeholder={t('eg. 1')}
                  value={referenceNumberValidator.value}
                  onChange={(e) => referenceNumberValidator.handleEvent(e.target.value)}
                  onBlur={(e) => referenceNumberValidator.handleEvent(e.target.value.trim())}
                  invalid={referenceNumberValidator.invalid}
                />
              </FormCol>
            </FormRow>
            <ValidationErrorMessage size={Size.Medium} validator={referenceNumberValidator} />
          </FormGroup>

          <FormGroup>
            <FormLabel>{t('Name as it appears on card')}</FormLabel>
            <Select
              value={middleNameValidator.value}
              options={nameOptions}
              onChange={(option) => middleNameValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Name as it appears on card')}
              isSearchable
              isClearable
              invalid={middleNameValidator.invalid}
              filterOption={filterByStartWith}
            />
            <ValidationErrorMessage size={Size.Medium} validator={middleNameValidator} />
          </FormGroup>

          {!!cardColourValidator.value && (
            <FormGroup>
              <FormLabel>{t('Valid to')}</FormLabel>
              <Date
                value={expiryDateValidator.value}
                onChange={(date) => {
                  expiryDateValidator.handleEvent(date);
                }}
                invalid={expiryDateValidator.invalid}
                validated={medicare.validated}
                hideDay={cardColourValidator.value === MedicareCardColour.Green}
                showMonthAsNumber
              />
              <ValidationErrorMessage size={Size.Medium} validator={expiryDateValidator} />
            </FormGroup>
          )}
        </CardContent>
        <CardSeparator />
        <CardFooter>
          <FormGroup>
            <CheckBox
              checked={consentToIdCheckValidator.value}
              label={(IsIndividualAccountType(accountType) ? consentToIdCheckLabelANZM : consentToIdCheckLabel)(t)}
              onChange={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              onBlur={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              invalid={consentToIdCheckValidator.invalid}
            />
            <ValidationErrorMessage size={Size.Medium} validator={consentToIdCheckValidator} />
          </FormGroup>
          <IdentificationCardFooter
            validationStatus={validationStatus}
            onSubmit={submitMedicareDetailsInternal}
            onClose={onNext}
            validators={[
              cardColourValidator,
              cardNumberValidator,
              referenceNumberValidator,
              expiryDateValidator,
              consentToIdCheckValidator,
              middleNameValidator
            ]}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
