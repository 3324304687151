import { useEffect } from 'react';

export const useDisableBackButton = () => {
  useEffect(() => {
    // Push state to initialize the history stack
    window.history.pushState({}, '', document.URL);
    const handlePopState = () => {
      window.history.pushState({}, '', document.URL);
    };
    // Add event listener for popstate
    window.addEventListener('popstate', handlePopState);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
};
