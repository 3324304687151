/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useRef, useState } from 'react';
import {
  breakpoint,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  CardLarge,
  CardSeparator,
  Heading1,
  Text,
  PasswordFormControl,
  Validators,
  useValidator,
  ValidationErrorMessage,
  Variant,
  Size,
  ButtonLink,
  useTestId,
  Icon,
  CardContent,
  CardFooter,
  Select,
  ISelectOption
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Brand } from '@cmctechnology/phoenix-stockbroking-api-client';
import { EventType } from '@cmctechnology/webinvest-analytics-compat';
import { emailAvailable, openExternalUrl, saveCountryOfResidence, signUp } from '../Store/Actions';
import { actions, IStore } from '../Store/Store';
import { emailRegex, FULL_OPACITY, HALF_OPACITY, mobilePhoneValidations, passwordValidations, confirmPasswordValidation } from '../constants/loginConstants';
import { ApiRequest, ApiRequestStatus } from '../models/apiRequestResult';
import { VerifyYourEmailModal } from './VerifyYourEmail';
import { getSignUpErrorMessage } from '../models/usersErrorCodes/signUpErrorCodes';
import { GoBackIcon } from '../Components/GoBackIcon';
import { IPageNavigation } from '../models/IPageNavigation';
import { getPasswordValidationVariantAndIcon } from '../common/validations';
import { CMC_PRIVACY_STATEMENT } from '../constants/urlConstants';
import { NoMarginTextSmall, getPasswordValidationsWithoutCheckingStartEndSpaces, startOrEndWithSpaces } from '../Components/PasswordRule';
import { CenteredButton } from '../Components/CenteredButton';
import { AutoComplete } from '../constants/autocompleteConstants';
import { FormGroupWithMoreBottomMargin } from '../Components/FormGroupWithMoreBottomMargin';
import { useBusinessRegion } from '../hooks/useBusinessRegion';
import { COUNTRIES_RESIDENCE, COUNTRY_CODE_SG } from '../models/sgp/country';
import { createKeyValueTranslatedToOptionList } from '../common/mappings';
import { BusinessRegion } from '../configuration/configuration';
import { getInternationalDiallingCode } from '../models/sgp/createLogin';
import { CardHeaderWithText } from '../Components/CardHeaderWithText';
import { SGP_CMC_PRIVACY_STATEMENT } from '../constants/sgp/urlConstants';
import { EMPTY_STRING } from '../constants/commonConstants';
import { Recaptcha } from '../Components/Recaptcha';
import { TrackEventNames } from '../constants/analyticsConstants';
import { trackEvent } from '../analytics/trackEvents';

const removeLeadingZeroAndWhiteSpace = (value: string) => value.trim().replace(/^0+/, '').replace(/\s/g, '');

const ButtonLinkWithoutUnderline = styled(ButtonLink)`
  text-decoration: none;
`;

const CardContentWithSmallPaddingBottom = styled(CardContent)`
  padding-bottom: 2rem;
  @media (max-width: ${breakpoint.mobile}) {
    width: 100%;
  }
`;

const CardFooterWithSmallPaddingTop = styled(CardFooter)`
  padding-top: 2rem;
`;

const PasswordFormControlContainer = styled.div`
  margin-bottom: 1rem;
`;

interface IBaseCreateLoginProps extends IPageNavigation {
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  onLogin: () => void;
}

const BaseCreateLogin: React.FC<IBaseCreateLoginProps> = ({ email, setEmail, password, setPassword, onNext, onLogin, onPrevious, ...rest }) => {
  const { generateTestId } = useTestId(rest, `login`);
  const { t } = useTranslation();
  const { businessRegion } = useBusinessRegion();
  const isAustraliaRegion = businessRegion === BusinessRegion.AUS;
  const brand = isAustraliaRegion ? Brand.cmc : Brand.cmcinvestsingapore;

  const privateStatementUrl = isAustraliaRegion ? CMC_PRIVACY_STATEMENT : SGP_CMC_PRIVACY_STATEMENT;
  const signUpStatus = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignUp].status);
  const signUpErrorCode = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignUp].errorCode);
  const usernameAvailableErrorCode = useSelector((store: IStore) => store.local.apiResults[ApiRequest.UsernameAvailable].errorCode);

  const dispatch = useDispatch();

  const countryOfResidenceSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_RESIDENCE, t);

  const refPassword = useRef(password);
  const refConfirmPassword = useRef(EMPTY_STRING);

  const countryOfResidenceValidator = useValidator(
    countryOfResidenceSelectOptions.find((x) => x.value === COUNTRY_CODE_SG),
    Validators.required(t('The Country of residence is required'))
  );

  const internationalDiallingCode = getInternationalDiallingCode(businessRegion as BusinessRegion, countryOfResidenceValidator.value?.value);

  const mobileValidator = useValidator<string>(
    '',
    Validators.required(t('Required')).match(mobilePhoneValidations[internationalDiallingCode], t('Please enter a valid phone number'))
  );

  const emailFormatValidator = useValidator<string>('', Validators.required(t('Required')).match(emailRegex, t('Please enter a valid email')));

  const emailAvailableValidator = useValidator<string>(
    '',
    Validators.required(t('Required'))
      .match(emailRegex, t('Please enter a valid email'))
      .custom(
        async (value) => emailAvailable(value, dispatch),
        usernameAvailableErrorCode === 'InvalidRecaptcha'
          ? t('This email failed the verification. Please try again.')
          : t(
              "This email address is being used. If you have already started an application, please click on 'Login' below to continue. Otherwise, please provide a different email address."
            )
      )
  );

  const validateConfirmPassword = () => refConfirmPassword.current !== '' && refPassword.current === refConfirmPassword.current;

  const confirmPasswordValidator = useValidator<string>(
    '',
    Validators.required(t('Confirm password is required')).custom(
      () => {
        return validateConfirmPassword();
      },
      confirmPasswordValidation.message(t),
      confirmPasswordValidation.id
    )
  );

  const passwordValidator = useValidator<string>(
    '',
    Validators.required(t('Required')).combine(passwordValidations.map((x) => Validators.match(x.regex, x.message(t), x.id)))
  );
  const isLoading = signUpStatus === ApiRequestStatus.InProgress;
  const sharedValidators = [mobileValidator, emailFormatValidator, emailAvailableValidator, passwordValidator, confirmPasswordValidator];
  const inEffectiveValidators = businessRegion === BusinessRegion.AUS ? sharedValidators : [...sharedValidators, countryOfResidenceValidator];

  useEffect(() => {
    if (signUpStatus === ApiRequestStatus.Success) {
      onNext();
    }
  }, [signUpStatus, onNext]);

  const onSubmit = () => {
    dispatch(saveCountryOfResidence(countryOfResidenceValidator.value?.value));
    if (businessRegion === BusinessRegion.SGP) trackEvent(TrackEventNames.ApplicationNext, EventType.Navigation, {});

    const signUpRequestDetails = {
      phoneNumber: `${internationalDiallingCode}${removeLeadingZeroAndWhiteSpace(mobileValidator.value)}`,
      email,
      password,
      brand
    };
    dispatch(signUp(signUpRequestDetails));
  };

  return (
    <Form>
      <Recaptcha />
      <CardLarge>
        <CardHeaderWithText textWithSmallMargin>
          {isAustraliaRegion && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Create login')}</Heading1>
          {!isAustraliaRegion && (
            <Text textAlign='center' size={Size.Large}>
              {t('Your login will give you access to your CMC Invest account')}
            </Text>
          )}
        </CardHeaderWithText>
        <CardSeparator />
        <CardContentWithSmallPaddingBottom>
          {!isAustraliaRegion && (
            <FormGroup>
              <FormLabel>{t('Country of residence')}</FormLabel>
              <Select
                autoFocus
                value={countryOfResidenceValidator.value}
                options={countryOfResidenceSelectOptions}
                onChange={(option) => countryOfResidenceValidator.handleEvent(option as ISelectOption)}
                placeholder={t('Select country')}
                isSearchable
                isClearable
                invalid={countryOfResidenceValidator.invalid}
                {...generateTestId(`country`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={countryOfResidenceValidator} />
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel>{t('Mobile')}</FormLabel>
            <FormLabel attached='left'>{internationalDiallingCode}</FormLabel>
            <FormControl
              autoFocus={businessRegion === BusinessRegion.AUS}
              type='tel'
              name='mobile'
              disabled={!countryOfResidenceValidator.value?.value && businessRegion === BusinessRegion.SGP}
              autoComplete={AutoComplete.PhoneNumber}
              placeholder={t('Enter your phone number')}
              value={mobileValidator.value}
              onChange={(e) => mobileValidator.handleEvent(e.target.value, !mobileValidator.validated)}
              onBlur={(e) => mobileValidator.handleEvent(e.target.value)}
              invalid={mobileValidator.invalid}
              {...generateTestId(`phone`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={mobileValidator} />
          </FormGroup>
          <FormGroup>
            <Text>
              <FormLabel>{t('Email (username)')}</FormLabel>
            </Text>

            <FormControl
              type='email'
              name='email'
              autoComplete={AutoComplete.Email}
              placeholder={t('Enter your email address')}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                emailFormatValidator.handleEvent(e.target.value, !emailFormatValidator.validated);
              }}
              onBlur={(e) => {
                emailAvailableValidator.handleEvent(e.target.value);
              }}
              invalid={emailFormatValidator.invalid || emailAvailableValidator.invalid}
              {...generateTestId(`email`)}
            />
            {(emailFormatValidator.validating || emailAvailableValidator.validating) && <Text size={Size.Medium}>{t('Checking...')}</Text>}
            {!emailFormatValidator.validating && <ValidationErrorMessage size={Size.Medium} validator={emailFormatValidator} />}
            {!emailAvailableValidator.validating && <ValidationErrorMessage size={Size.Medium} validator={emailAvailableValidator} />}
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Password')}</FormLabel>
            <PasswordFormControlContainer>
              <PasswordFormControl
                placeholder={t('Create password')}
                name='password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  refPassword.current = e.target.value;
                  passwordValidator.handleEvent(e.target.value);
                }}
                onBlur={(e) => passwordValidator.handleEvent(e.target.value)}
                invalid={passwordValidator.invalid}
                {...generateTestId(`password`)}
              />
              {startOrEndWithSpaces(passwordValidator) && (
                <Text size={Size.ExtraSmall} variant={Variant.Error}>
                  {t('Cannot start or end with spaces')}
                </Text>
              )}
              <FormLabel>{t('Re-enter Password')}</FormLabel>
              <PasswordFormControl
                name='confirm_password'
                placeholder={t('Re-enter your password')}
                onChange={(e) => {
                  refConfirmPassword.current = e.target.value;
                  confirmPasswordValidator.handleEvent(e.target.value);
                }}
                onBlur={(e) => confirmPasswordValidator.handleEvent(e.target.value)}
                invalid={confirmPasswordValidator.invalid}
                {...generateTestId(`confirm_password`)}
              />
            </PasswordFormControlContainer>
            {getPasswordValidationsWithoutCheckingStartEndSpaces(passwordValidations).map((validation) => {
              const validationResult = passwordValidator.results.find((x) => x.id === validation.id);
              return (
                <NoMarginTextSmall key={validation.id} size={Size.Small}>
                  <Icon
                    {...getPasswordValidationVariantAndIcon(passwordValidator.validated, validationResult)}
                    opacity={passwordValidator.validated ? FULL_OPACITY : HALF_OPACITY}
                    size={Size.Small}
                  />
                  {validation.message(t)}
                </NoMarginTextSmall>
              );
            })}
            <NoMarginTextSmall size={Size.Small}>
              <Icon
                {...getPasswordValidationVariantAndIcon(
                  confirmPasswordValidator.validated || passwordValidator.validated,
                  confirmPasswordValidator.results.find((x) => x.id === confirmPasswordValidation.id)
                )}
                opacity={passwordValidator.validated || confirmPasswordValidator.validated ? FULL_OPACITY : HALF_OPACITY}
                size={Size.Small}
              />
              {confirmPasswordValidation.message(t)}
            </NoMarginTextSmall>
          </FormGroup>
        </CardContentWithSmallPaddingBottom>
        <CardSeparator />
        <CardFooterWithSmallPaddingTop>
          {signUpStatus === ApiRequestStatus.Failed && signUpErrorCode && (
            <Text variant={Variant.Error} textAlign='center' size={Size.Small} {...generateTestId(`error.${signUpErrorCode}`)}>
              {getSignUpErrorMessage(signUpErrorCode, t)}
            </Text>
          )}
          <FormGroupWithMoreBottomMargin>
            <Text textAlign='center' size={Size.Large}>
              <Trans t={t}>
                By clicking &apos;Next&apos;, I agree and consent to the collection and handling of my personal information in accordance with the
              </Trans>
              <ButtonLinkWithoutUnderline onClick={() => openExternalUrl(privateStatementUrl)}>{t('CMC Invest Privacy Policy')}</ButtonLinkWithoutUnderline>
            </Text>
          </FormGroupWithMoreBottomMargin>
          <CenteredButton
            label={isLoading ? t('Processing') : t('Next')}
            onClick={onSubmit}
            {...generateTestId(`submit`)}
            validators={[...inEffectiveValidators]}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Text size={Size.Large} textAlign='center'>
            {t('Already started an application?')}{' '}
            <ButtonLink onClick={onLogin} {...generateTestId(`alreadyCustomer`)}>
              {t('Login')}
            </ButtonLink>
          </Text>
        </CardFooterWithSmallPaddingTop>
      </CardLarge>
    </Form>
  );
};

export interface ICreateLoginProps extends IPageNavigation {
  onLogin: () => void;
}

export const CreateLogin: React.FC<ICreateLoginProps> = ({ onNext, onLogin, onPrevious }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showVerifyYourEmail, setShowVerifyYourEmail] = useState(false);

  const { businessRegion } = useBusinessRegion();

  const dispatch = useDispatch();

  useEffect(() => {
    if (showVerifyYourEmail && !password) {
      dispatch(actions.createLoginRestarted());
      setShowVerifyYourEmail(false);
    }
  }, [password, showVerifyYourEmail]);

  return showVerifyYourEmail ? (
    <VerifyYourEmailModal email={email} password={password} onNext={onNext} />
  ) : (
    <BaseCreateLogin
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      onNext={businessRegion === BusinessRegion.AUS ? () => setShowVerifyYourEmail(true) : onNext}
      onLogin={onLogin}
      onPrevious={onPrevious}
    />
  );
};
