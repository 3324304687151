/* eslint-disable no-console */
import React, { useRef } from 'react';
import {
  Form,
  FormGroup,
  FormLabel,
  RadioButtonGroup,
  RadioButton,
  RadioButtonLabel,
  FormControl,
  CardContent,
  CardLarge,
  CardSeparator,
  Heading1,
  Text,
  useValidator,
  Validators,
  ValidationErrorMessage,
  Size,
  CardHeader,
  useTestId,
  Select,
  ISelectOption
} from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { DayMonthYear1, PersonalDetails } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ENABLED_TABINDEX_FOR_NON_INPUT, OTHER_PERSON_TITLES, PERSON_TITLES, PERSON_TITLE_OTHER } from '../constants/commonConstants';
import { IStore } from '../Store/Store';
import { submitPersonalDetails } from '../Store/Actions';
import { CONFIRM_DOB_VALIDATION_MESSAGES, DOB_VALIDATION_MESSAGES } from '../constants/validationConstants';
import { dateValidator } from '../validators/dateValidator';
import { ProfileCardFooter } from '../Components/ProfileCardFooter';
import { Date } from '../Components/Date';
import { GoBackIcon } from '../Components/GoBackIcon';
import { INextPage, IPreviousPage } from '../models/IPageNavigation';
import { ifEnterKey } from '../events/onKeyDown';
import { createKeyValueTranslatedToOptionList } from '../common/mappings';
import { isNotEmpty } from '../utils/stringUtils';
import { AutoComplete } from '../constants/autocompleteConstants';
import {
  FIRST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX,
  LAST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX,
  invalidMiddleNameValidations,
  DATE_FORMAT,
  MINIMUM_AGE,
  MIDDLE_NAME_LENGTH_WHEN_NO_VALUE,
  MIDDLE_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX,
  FORMATTED_MONTH_FIXED_LENGTH
} from '../constants/personalDetails';
import {
  getErrorMessageForFirstNameAllowedCharacters,
  getErrorMessageForLastNameAllowedCharacters,
  getErrorMessageForMiddleNameAllowedCharacters,
  getErrorMessageForNameStartingWithFullStop,
  isNameNotStartedWithFullStop
} from '../models/personalDetails';
import { ISelectOptionOrUndefined } from '../models/ISelectOptionOrUndefined';
import { isEqualDayMonthYear } from '../utils/dateUtils';

export interface IProfilePersonalDetailsProps extends INextPage, Partial<IPreviousPage> {}

const PaddingSelect = styled(Select)`
  padding: 0 0.25rem;
`;

const OtherTitleLabel = styled(FormLabel)`
  margin-top: 0.25rem;
  margin-left: 0.25rem;
`;

export const ProfilePersonalDetails: React.FC<IProfilePersonalDetailsProps> = ({ onNext, onPrevious, ...rest }) => {
  const personalDetails = useSelector((store: IStore) => store.remote.profile.personalDetails);
  const refConfirmDob = useRef<DayMonthYear1>();

  const validatorConfig = {
    validated: personalDetails.validated
  };

  const { t } = useTranslation();
  const otherTitleSelectOptions = createKeyValueTranslatedToOptionList(OTHER_PERSON_TITLES, t);
  const titleValidator = useValidator(
    isNotEmpty(personalDetails.title) ? (PERSON_TITLES[personalDetails.title] ? personalDetails.title : PERSON_TITLE_OTHER) : personalDetails.title,
    Validators.required(t('Required')),
    validatorConfig
  );
  const firstNameValidator = useValidator(
    personalDetails.firstName,
    Validators.required(t('Required'))
      .match(FIRST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX, getErrorMessageForFirstNameAllowedCharacters(t))
      .custom((value: string) => isNameNotStartedWithFullStop(value), getErrorMessageForNameStartingWithFullStop(t)),
    validatorConfig
  );
  const middleNameValidator = useValidator(
    personalDetails.middleName,
    Validators.combine(
      Object.keys(invalidMiddleNameValidations).map((key) =>
        Validators.custom((value) => !invalidMiddleNameValidations[key].regex.test(value), invalidMiddleNameValidations[key].message(t), key)
      )
    ).custom(
      (value: string) =>
        value.length === MIDDLE_NAME_LENGTH_WHEN_NO_VALUE ||
        (value.length > MIDDLE_NAME_LENGTH_WHEN_NO_VALUE && MIDDLE_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX.test(value)),
      getErrorMessageForMiddleNameAllowedCharacters(t)
    ),
    validatorConfig
  );
  const lastNameValidator = useValidator(
    personalDetails.lastName,
    Validators.required(t('Required'))
      .match(LAST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX, getErrorMessageForLastNameAllowedCharacters(t))
      .custom((value: string) => isNameNotStartedWithFullStop(value), getErrorMessageForNameStartingWithFullStop(t)),
    validatorConfig
  );
  const otherTitleValidator = useValidator<ISelectOptionOrUndefined>(
    otherTitleSelectOptions.find((x) => x.value === personalDetails.title),
    titleValidator.value === PERSON_TITLE_OTHER ? Validators.required(t('Required')) : Validators.custom(() => true, ''),
    validatorConfig
  );

  const dispatch = useDispatch();

  const isInvalidDob = (value: DayMonthYear1) => {
    const dateStr = `${value.year}/${value.month.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0')}/${value.day.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0')}`;
    return !value.day || !value.month || !value.year || dayjs(dateStr, DATE_FORMAT).format(DATE_FORMAT) !== dateStr;
  };

  const isDob18YearsAbove = (value: DayMonthYear1) => {
    const dob18YearsAbove = dateValidator.checkYearsAbove({ day: value.day, month: value.month, year: value.year }, MINIMUM_AGE);
    return dob18YearsAbove;
  };

  const dobValidator = useValidator<DayMonthYear1>(
    personalDetails.dob,
    Validators.custom((value: DayMonthYear1) => !isInvalidDob(value), DOB_VALIDATION_MESSAGES.InvalidDob.message(t)).custom(
      (value: DayMonthYear1) => isDob18YearsAbove(value),
      DOB_VALIDATION_MESSAGES.Over18Years.message(t)
    ),
    validatorConfig
  );

  const confirmDobValidator = useValidator<DayMonthYear1>(
    { day: '', month: '', year: '' },
    Validators.required(t('Required')).custom(
      () => isEqualDayMonthYear(dobValidator.value, refConfirmDob.current),
      CONFIRM_DOB_VALIDATION_MESSAGES.NotMatch.message(t)
    ),
    validatorConfig
  );

  // TODO move to web-design and should also add validator handle function
  const onTitleChanged = (title: string) => {
    titleValidator.handleEvent(title);
    otherTitleValidator.validate();
  };

  const onNextClicked = () => {
    let titleCheck = titleValidator.value !== PERSON_TITLE_OTHER ? titleValidator.value : otherTitleValidator.value!.value;
    if (titleCheck === t('Professor')) {
      titleCheck = t('Prof.');
    }
    const person: PersonalDetails = {
      title: titleCheck,
      firstName: firstNameValidator.value,
      middleName: middleNameValidator.value,
      lastName: lastNameValidator.value,
      dob: { day: dobValidator.value.day, month: dobValidator.value.month, year: dobValidator.value.year }
    };

    dispatch(submitPersonalDetails(person));
    onNext();
  };

  const { generateTestId } = useTestId(rest, `profile`);

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          {onPrevious && <GoBackIcon onClick={onPrevious} tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT} />}
          <Heading1>{t('Personal details')}</Heading1>
        </CardHeader>
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <FormLabel>{t('Title')}</FormLabel>
            <RadioButtonGroup>
              {Object.keys(PERSON_TITLES).map((titleName) => (
                <React.Fragment key={titleName}>
                  <RadioButton
                    name='title'
                    id={titleName}
                    value={titleName}
                    checked={titleValidator.value === titleName}
                    onChange={(e) => {
                      titleValidator.handleEvent(e.target.value);
                      otherTitleValidator.validate();
                    }}
                    invalid={titleValidator.invalid}
                  />
                  <RadioButtonLabel
                    htmlFor={titleName}
                    tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT}
                    onKeyDown={(e) => ifEnterKey(e, () => onTitleChanged((e.target as HTMLLabelElement).htmlFor))}
                  >
                    {PERSON_TITLES[titleName](t)}
                  </RadioButtonLabel>
                </React.Fragment>
              ))}
            </RadioButtonGroup>
            {titleValidator.value === PERSON_TITLE_OTHER && <OtherTitleLabel>{t('Other title')}</OtherTitleLabel>}
            {titleValidator.value === PERSON_TITLE_OTHER && (
              <PaddingSelect
                value={otherTitleValidator.value}
                options={otherTitleSelectOptions}
                onChange={(option) => otherTitleValidator.handleEvent(option as ISelectOption)}
                placeholder={t('Please select a title')}
                invalid={otherTitleValidator.invalid}
                {...generateTestId(`otherTitle`)}
              />
            )}
            {titleValidator.value === PERSON_TITLE_OTHER && <ValidationErrorMessage validator={otherTitleValidator} />}
            <ValidationErrorMessage size={Size.Medium} validator={titleValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('First name')}</FormLabel>
            <FormControl
              type='text'
              name='firstName'
              autoComplete={AutoComplete.FirstName}
              placeholder={t('Enter your first name')}
              value={firstNameValidator.value}
              onChange={(e) => firstNameValidator.handleEvent(e.target.value, !firstNameValidator.validated)}
              onBlur={(e) => firstNameValidator.handleEvent(e.target.value.trim())}
              invalid={firstNameValidator.invalid}
              {...generateTestId(`firstName`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={firstNameValidator} />
            <Text size={Size.Medium}>{t('As per on your photo ID')}</Text>
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Middle name (optional)')}</FormLabel>
            <FormControl
              type='text'
              name='middleName'
              autoComplete={AutoComplete.MiddleName}
              placeholder={t('Enter your middle name')}
              value={middleNameValidator.value}
              onChange={(e) => middleNameValidator.handleEvent(e.target.value, !middleNameValidator.validated)}
              onBlur={(e) => middleNameValidator.handleEvent(e.target.value.trim())}
              invalid={middleNameValidator.invalid}
              {...generateTestId(`middleName`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={middleNameValidator} />
            <Text size={Size.Medium}>{t('If your middle name appears on your ID please enter it')}</Text>
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Last name')}</FormLabel>
            <FormControl
              type='text'
              name='lastName'
              autoComplete={AutoComplete.LastName}
              placeholder={t('Enter your last name')}
              value={lastNameValidator.value}
              onChange={(e) => lastNameValidator.handleEvent(e.target.value, !lastNameValidator.validated)}
              onBlur={(e) => lastNameValidator.handleEvent(e.target.value.trim())}
              invalid={lastNameValidator.invalid}
              {...generateTestId(`lastName`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={lastNameValidator} />
            <Text size={Size.Medium}>{t('As per on your photo ID')}</Text>
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Date of birth')}</FormLabel>
            <Date
              value={dobValidator.value}
              onChange={(newDob) => {
                dobValidator.handleEvent(newDob);
              }}
              invalid={dobValidator.invalid}
              validated={personalDetails.validated}
              {...generateTestId(`dob`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={dobValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Confirm date of birth')}</FormLabel>
            <Date
              value={confirmDobValidator.value}
              onChange={(newDob) => {
                refConfirmDob.current = newDob;
              }}
              invalid={confirmDobValidator.invalid}
              validated={personalDetails.validated}
              {...generateTestId(`confirm_dob`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={confirmDobValidator} />
          </FormGroup>
        </CardContent>
        <ProfileCardFooter
          nextButtonLabel={t('Next')}
          saveButtonLabel={t('Save')}
          onNext={onNextClicked}
          onCancel={onNext}
          validators={[titleValidator, otherTitleValidator, firstNameValidator, middleNameValidator, lastNameValidator, dobValidator, confirmDobValidator]}
        />
      </CardLarge>
    </Form>
  );
};
