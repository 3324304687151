import { BankName } from '../constants/fundYourAccount';
import { AccountTypeInput } from './application';
import { KeyValueTranslated } from './translation';

export const AccountTypeToBankNameMappings: Record<AccountTypeInput, BankName> = {
  [AccountTypeInput.Individual]: BankName.ANZ,
  [AccountTypeInput.Joint]: BankName.ANZM,
  [AccountTypeInput.Minor]: BankName.ANZ,
  [AccountTypeInput.SMSF]: BankName.Macquarie,
  [AccountTypeInput.Company]: BankName.Macquarie,
  [AccountTypeInput.Trust]: BankName.ANZ
};

export const OpenCashAccountDescription: KeyValueTranslated = {
  [BankName.ANZ]: (t) => t('Make trades fast and fuss-free with your own money, held in a safe ANZ cash account'),
  [BankName.ANZM]: (t) => t('Make trades fast and fuss-free with your money, held in a safe ANZ Client Money Management account'),
  [BankName.Macquarie]: (t) => t('Make trades fast and fuss-free with your own money, held in a safe Macquarie Cash Management Account')
};
