import {
  DayMonthYear1,
  DriversLicenceDetails,
  MedicareCardColour,
  PassportDetails,
  ValidationOutcome1,
  VerificationOutcome1
} from '@cmctechnology/phoenix-stockbroking-api-client';
import { EMPTY_STRING } from '../constants/commonConstants';
import { defaultDateInDayMonthYear } from './common';
import { INextPage } from './IPageNavigation';
import { KeyValueTranslated } from './translation';

export interface IDriversLicenceDetails extends DriversLicenceDetails {
  consentToIdCheck: boolean;
  licenceCardNumber?: string;
}

export interface IPassportDetails extends PassportDetails {
  consentToIdCheck: boolean;
}

// UI IMedicareDetails is different with api MedicareDetails due to cardColour
export interface IMedicareDetails {
  consentToIdCheck: boolean;
  cardColour?: MedicareCardColour;
  cardNumber: string;
  expiryDate: DayMonthYear1;
  referenceNumber: string;
  validated?: boolean;
  middleName?: string | null;
}

export enum IdentificationValidationStatus {
  'NotStarted' = 'NotStarted',
  'InProgress' = 'InProgress',
  'Failed' = 'Failed',
  'Passed' = 'Passed'
}

// Map back end status to the UI status
export const IdentificationValidationStatusMapping: Record<VerificationOutcome1, IdentificationValidationStatus> = {
  [VerificationOutcome1.ACCEPT]: IdentificationValidationStatus.Passed,
  [VerificationOutcome1.ERROR]: IdentificationValidationStatus.Failed,
  [VerificationOutcome1.IN_PROGRESS]: IdentificationValidationStatus.InProgress, // UI only needs to show a modal to let user exit application, no need to re-poll again
  [VerificationOutcome1.REJECT]: IdentificationValidationStatus.Failed,
  [VerificationOutcome1.REJECT_ON_EXCLUSION]: IdentificationValidationStatus.Failed,
  [VerificationOutcome1.TIMEOUT]: IdentificationValidationStatus.Failed
};

export const hasExceededRequestLimitForVerifyIdStatus = (errorCode: string | undefined | null) => {
  const errorCodeEnum = errorCode as ValidationOutcome1;
  return errorCodeEnum === ValidationOutcome1.RequestLimitExceeded;
};

export interface IIdentificationDetails {
  identificationType?: IdentificationType;
  driversLicence?: IDriversLicenceDetails;
  passport?: IPassportDetails;
  medicare?: IMedicareDetails;
  status: IdentificationValidationStatus;
  identityQuestionsRequired?: boolean;
}

export interface IIdentificationComponentProps extends INextPage {
  onGoBack: () => void;
  onTryAgain: () => void;
}

export enum IdentificationType {
  DriversLicence = 'DriversLicence',
  Passport = 'Passport',
  Medicare = 'Medicare',
  None = 'None'
}

export const MEDICARE_CARD_COLOURS: KeyValueTranslated = {
  [MedicareCardColour.Green]: (t) => t('Green'),
  [MedicareCardColour.Blue]: (t) => t('Blue'),
  [MedicareCardColour.Yellow]: (t) => t('Yellow')
};

export const consentToIdCheckLabel = (t: any) =>
  t(
    'I consent to CMC Markets Stockbroking Limited ("CMC Markets Stockbroking") collecting my personal information for the purposes of this account application and disclosing it to third parties in order to verify my identity.'
  );

export const consentToIdCheckLabelANZM = (t: any) =>
  t(
    'I consent to CMC Markets Stockbroking Limited ("CMC Markets Stockbroking") collecting my personal information for the purposes of this account application and being checked with the document issuer or official record holder via a third party system in order to verify my identity.'
  );

export const defaultDriversLicence: IDriversLicenceDetails = {
  consentToIdCheck: false,
  licenceNumber: EMPTY_STRING,
  licenceState: EMPTY_STRING,
  licenceCardNumber: EMPTY_STRING
};

export const defaultPassport: IPassportDetails = { consentToIdCheck: false, countryOfIssue: EMPTY_STRING, passportNumber: EMPTY_STRING };

export const defaultMedicare: IMedicareDetails = {
  consentToIdCheck: false,
  cardNumber: EMPTY_STRING,
  expiryDate: defaultDateInDayMonthYear,
  referenceNumber: EMPTY_STRING
};
