import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CardContent,
  CardLarge,
  CardSeparator,
  CheckBox,
  Form,
  FormControl,
  FormGroup,
  Heading3,
  Label,
  CardFooter,
  useValidator,
  Validators,
  ValidationErrorMessage,
  ISelectOption,
  Select,
  Size,
  useModalState
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { IStore } from '../Store/Store';
import { submitPassportDetails } from '../Store/Actions';
import { consentToIdCheckLabel, consentToIdCheckLabelANZM, defaultPassport, IIdentificationComponentProps, IPassportDetails } from '../models/identification';
import { COUNTRIES_ALL, COUNTRY_CODE_AUS } from '../constants/countryConstants';
import { FIELD_REGEX_RULES } from '../constants/validationConstants';
import { createCountriesOptionList, createKeyValueTranslatedToOptionList } from '../common/mappings';
import { IdentificationCardFooter } from '../Components/IdentificationCardFooter';
import { IdentificationCardHeader } from '../Components/IdentificationCardHeader';
import { filterByStartWith } from '../models/filterOption';
import { useIdValidationErrorMessageModal } from '../hooks/useIdValidationErrorMessageModal';
import { AccountTypeInput, IsIndividualAccountType } from '../models/application';

export const IdentificationPassport: React.FC<IIdentificationComponentProps> = ({ onNext, onGoBack, onTryAgain }) => {
  const passport = useSelector((store: IStore) => store.remote.identificationDetails.passport || defaultPassport);
  const [, setModalState] = useModalState();
  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);
  const accountType = useSelector((store: IStore) => store.local.accountType || AccountTypeInput.Individual);

  const validatorConfig = {
    debounceMs: 0
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countriesSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t);
  const countryOfIssueValidator = useValidator(
    countriesSelectOptions.find((x) => x.value === passport?.countryOfIssue),
    Validators.required(t('The Country of issue is required')),
    validatorConfig
  );

  const passportNumberValidator = useValidator(
    passport?.passportNumber,
    Validators.required(t('Required')).custom(
      (value) =>
        countryOfIssueValidator.value?.value === COUNTRY_CODE_AUS
          ? FIELD_REGEX_RULES.AustralianPassportNumber.pattern.test(value)
          : FIELD_REGEX_RULES.InternationalPassportNumber.pattern.test(value),
      countryOfIssueValidator.value?.value === COUNTRY_CODE_AUS
        ? FIELD_REGEX_RULES.AustralianPassportNumber.message(t)
        : FIELD_REGEX_RULES.InternationalPassportNumber.message(t)
    ),
    validatorConfig
  );

  const consentToIdCheckValidator = useValidator(
    passport?.consentToIdCheck,
    Validators.custom((value) => value, t('Required')),
    validatorConfig
  );

  const validationStatus = useIdValidationErrorMessageModal(setModalState, onNext, onGoBack, onTryAgain);

  const submitPassportDetailsInternal = () => {
    const passportDetails: IPassportDetails = {
      consentToIdCheck: consentToIdCheckValidator.value,
      countryOfIssue: countryOfIssueValidator.value!.value,
      passportNumber: passportNumberValidator.value
    };

    dispatch(submitPassportDetails(passportDetails));
  };

  return (
    <Form>
      <CardLarge>
        <IdentificationCardHeader onGoBack={!reviewSection ? onTryAgain : undefined} />
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <Heading3>{t('Passport')}</Heading3>
          </FormGroup>
          <FormGroup>
            <Label>{t('Country of issue')}</Label>
            <Select
              value={countryOfIssueValidator.value}
              options={createCountriesOptionList(countriesSelectOptions, t)}
              onChange={(option) => countryOfIssueValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select country')}
              isSearchable
              isClearable
              invalid={countryOfIssueValidator.invalid}
              filterOption={filterByStartWith}
            />
            <ValidationErrorMessage size={Size.Medium} validator={countryOfIssueValidator} />
          </FormGroup>
          <FormGroup>
            <Label>{t('Passport number')}</Label>
            <FormControl
              type='text'
              placeholder={t('Enter your passport number')}
              value={passportNumberValidator.value}
              onChange={(e) => passportNumberValidator.handleEvent(e.target.value)}
              onBlur={(e) => passportNumberValidator.handleEvent(e.target.value.trim())}
              invalid={passportNumberValidator.invalid}
            />
            <ValidationErrorMessage size={Size.Medium} validator={passportNumberValidator} />
          </FormGroup>
        </CardContent>
        <CardSeparator />
        <CardFooter>
          <FormGroup>
            <CheckBox
              checked={consentToIdCheckValidator.value}
              label={(IsIndividualAccountType(accountType) ? consentToIdCheckLabelANZM : consentToIdCheckLabel)(t)}
              onChange={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              onBlur={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              invalid={consentToIdCheckValidator.invalid}
            />
            <ValidationErrorMessage size={Size.Medium} validator={consentToIdCheckValidator} />
          </FormGroup>
          <IdentificationCardFooter
            validationStatus={validationStatus}
            onSubmit={submitPassportDetailsInternal}
            onClose={onNext}
            validators={[countryOfIssueValidator, passportNumberValidator, consentToIdCheckValidator]}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
