import {
  FormControl,
  FormControlIcon,
  FormGroup,
  FormLabel,
  Text,
  ValidationErrorMessage,
  FaSolidIconNames,
  Variant,
  Size,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import { useEffect, useState } from 'react';

const ValidateIcon = styled(FormControlIcon)`
  margin-bottom: 0.5rem;
`;

const PromoValidation = styled.div`
  margin-top: 0.5rem;
`;

const PromoInput = styled(FormControl)`
  padding: 0.75rem;
`;

type IPromoCodeInputProps = {
  validator: IValidator<string>;
  promoCode?: string;
  setPromoCode: React.Dispatch<any>;
};

export const PromoCodeInput: React.FC<IPromoCodeInputProps> = ({ validator, promoCode, setPromoCode, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `Promo`);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      if (!window?.localStorage?.PromoCode) return;

      setIsDisabled(true);
      const isValid = await validator.validate();

      if (!isValid) setIsDisabled(false);
    })();
  }, []);

  return (
    <FormGroup>
      <FormLabel>{t('Promotion Code (Optional)')}</FormLabel>
      <PromoInput
        type='text'
        name='promoCode'
        value={promoCode}
        onChange={(e) => {
          validator.handleEvent(e.target.value, !validator.validated);
          setPromoCode(e.target.value);
        }}
        disabled={isDisabled}
        onBlur={(e) => {
          validator.handleEvent(e.target.value);
          setPromoCode(e.target.value);
        }}
        {...generateTestId(`input`)}
      />
      <PromoValidation>
        {validator.validating && <Text size={Size.Medium}>{t('Checking...')}</Text>}
        {!validator.validating && validator.valid && validator.value && <ValidateIcon fasName={FaSolidIconNames.faCheckCircle} variant={Variant.Success} />}
        {!validator.validating && validator.invalid && validator.value && (
          <ValidateIcon fasName={FaSolidIconNames.faExclamationCircle} variant={Variant.Error} />
        )}
        {!validator.validating && <ValidationErrorMessage size={Size.Medium} validator={validator} />}
      </PromoValidation>
    </FormGroup>
  );
};
