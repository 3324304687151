import { BankName } from '../constants/fundYourAccount';
import { KeyValueTranslated } from './translation';

export enum Tooltips {
  PostalAddress,
  TfnNumber,
  CountryOfTaxResidence,
  OtherCountryOfTaxResidence,
  CreateSecurePassword,
  FundIntercept
}

export const tooltipLabel: KeyValueTranslated<Tooltips> = {
  [Tooltips.PostalAddress]: (t) => t('Postal address'),
  [Tooltips.TfnNumber]: (t) => t('Australian Tax File Number (optional)'),
  [Tooltips.CountryOfTaxResidence]: (t) => t('Country of tax residence'),
  [Tooltips.OtherCountryOfTaxResidence]: (t) => t('Other country of tax residence'),
  [Tooltips.CreateSecurePassword]: (t) => t('Create a secure password.'),
  [Tooltips.FundIntercept]: (t) => t('Here are your ANZ Bank details.')
};

export const tooltipText: KeyValueTranslated<Tooltips> = {
  [Tooltips.PostalAddress]: (t) => t('This will be the address we use to register your shares.'),
  [Tooltips.TfnNumber]: (t) =>
    t(
      'Providing your Tax File Number or selecting a tax exemption is optional. To make sure the right amount of tax is taken out of your interest and any Australian investment distributions, we recommend that you provide these details. '
    ),
  [Tooltips.CountryOfTaxResidence]: (t) =>
    t('This information is required to comply with the Foreign Account Tax Compliance Act (FATCA) and Common Reporting Standard (CRS).'),
  [Tooltips.OtherCountryOfTaxResidence]: (t) =>
    t('This information is required to comply with the Foreign Account Tax Compliance Act (FATCA) and Common Reporting Standard (CRS).'),
  [Tooltips.CreateSecurePassword]: (t) =>
    t(
      'Use as many characters as you can – a longer password is harder to decipher. Avoid anything that can be easily guessed, such as sequences, or your address or birthday. Consider a passphrase; instead of creating a string of letters, numbers and symbols, use words that tell a story.'
    ),
  [Tooltips.FundIntercept]: (t) => t('You can also find these details in the platform in the Account menu under Transfer Funds.')
};

export const CashAccountTooltipText: KeyValueTranslated<BankName> = {
  [BankName.ANZ]: (t) =>
    t(
      'The ANZ Cash Account is a bank account opened on your behalf by CMC Markets Invest. This account is for trading and settlement purposes only and enables you to trade online using any of the CMC Markets Invest platforms.'
    ),
  [BankName.ANZM]: (t) =>
    t(
      'The ANZ Cash Account is a bank account opened on your behalf by CMC Markets Invest. This account is for trading and settlement purposes only and enables you to trade online using any of the CMC Markets Invest platforms.'
    ),
  [BankName.Macquarie]: (t) =>
    t(
      'The Macquarie Cash Management Account is a bank account opened on your behalf by CMC Markets Invest. This account is for trading and settlement purposes only and enables you to trade online using any of the CMC Markets Invest platforms.'
    )
};
