import {
  AccountType,
  GlobalScreeningPepQuestion,
  IdentificationDetails,
  MedicareCardColour,
  MedicareCardColourType,
  OnlineApplicationRequest,
  Profile,
  TaxResidentType
} from '@cmctechnology/phoenix-stockbroking-api-client';
import { PaymentMethod, AccountTypeInput } from '../models/application';
import { IStore } from '../Store/Store';
import { OccupationOthersType } from '../models/profile';
import { removeWhiteSpace } from '../utils/stringUtils';
import { IdentificationType, IdentificationValidationStatus } from '../models/identification';
import { IPEPQuestionsModel } from '../models/pepQuestions';
import { getPromoCode } from '../hooks/getPromoCode';
import { createConfig } from '../configuration/configuration';

const MARGIN_ENDPOINT = '/margin';
const convertMedicareColour = (colour: MedicareCardColour) => {
  switch (colour) {
    case MedicareCardColour.Blue:
      return MedicareCardColourType.B;
    case MedicareCardColour.Yellow:
      return MedicareCardColourType.Y;
    default:
      return MedicareCardColourType.G;
  }
};

const getMessageIdOfSuccessfulIdVerify = (
  status: IdentificationValidationStatus,
  identificationType?: IdentificationType,
  identityVerifyMessageId?: string
) => {
  return identificationType && identificationType !== IdentificationType.None && status === IdentificationValidationStatus.Passed
    ? identityVerifyMessageId
    : undefined;
};

const getPEPQuestions = (questions: IPEPQuestionsModel): GlobalScreeningPepQuestion => {
  const livedOtherCoutry: boolean = questions.question1.answered;
  const otherCitizenships: boolean = questions.question2.answered;
  const otherName: boolean = questions.question3.answered;
  const seniorGovermentRole: boolean = questions.question4.answered;
  const criminalOffence: boolean = questions.question5.answered;
  const govermentRegulator: boolean = questions.question6.answered;

  const result: GlobalScreeningPepQuestion = {
    LivedOtherCountry: {
      Answer: livedOtherCoutry,
      Country: questions.question1.country,
      City: questions.question1.city,
      DurationMonths: livedOtherCoutry ? Number.parseInt(`${questions.question1.durationMonths}`, 10) : undefined,
      DurationYears: livedOtherCoutry ? Number.parseInt(`${questions.question1.durationYears}`, 10) : undefined
    },
    OtherCitizenship: {
      Answer: otherCitizenships,
      Country1: questions.question2.country,
      DurationMonth1: questions.question2.country !== '' ? Number.parseInt(`${questions.question2.durationMonths}`, 10) : undefined,
      DurationYear1: questions.question2.country !== '' ? Number.parseInt(`${questions.question2.durationYears}`, 10) : undefined,
      Country2: questions.question2.country2,
      DurationMonth2: questions.question2.country2 !== '' ? Number.parseInt(`${questions.question2.durationMonths2}`, 10) : undefined,
      DurationYear2: questions.question2.country2 !== '' ? Number.parseInt(`${questions.question2.durationYears2}`, 10) : undefined,
      Country3: questions.question2.country3,
      DurationMonth3: questions.question2.country3 !== '' ? Number.parseInt(`${questions.question2.durationMonths3}`, 10) : undefined,
      DurationYear3: questions.question2.country3 !== '' ? Number.parseInt(`${questions.question2.durationYears3}`, 10) : undefined
    },
    OtherName: {
      Answer: otherName,
      FirstName: questions.question3.firstName,
      MiddleName: questions.question3.middleName,
      LastName: questions.question3.lastName
    },
    SeniorGovernmentRole: {
      Answer: seniorGovermentRole,
      FromDate: seniorGovermentRole
        ? {
            Month: Number.parseInt(`${questions.question4.fromMonth}`, 10) - 1,
            Year: Number.parseInt(`${questions.question4.fromYear}`, 10)
          }
        : undefined,
      ToDate: seniorGovermentRole
        ? {
            Month: Number.parseInt(`${questions.question4.toMonth}`, 10) - 1,
            Year: Number.parseInt(`${questions.question4.toYear}`, 10)
          }
        : undefined,
      Details: questions.question4.position
    },
    CriminalOffence: {
      Answer: criminalOffence,
      Date: criminalOffence
        ? {
            Month: Number.parseInt(`${questions.question5.month}`, 10) - 1,
            Year: Number.parseInt(`${questions.question5.year}`, 10)
          }
        : undefined,
      Details: questions.question5.offence
    },
    PenalisedByGovernmentRegulator: {
      Answer: govermentRegulator,
      Date: govermentRegulator
        ? {
            Month: Number.parseInt(`${questions.question6.month}`, 10) - 1,
            Year: Number.parseInt(`${questions.question6.year}`, 10)
          }
        : undefined,
      Details: questions.question6.disqualification
    }
  };
  return result;
};

export const applicationConverter = {
  convertToOnlineApplicationRequest: (currentState: IStore) => {
    const remotePart = currentState.remote;
    const localPart = currentState.local;
    const profile: Profile = {
      ...remotePart.profile,
      taxDetails: {
        ...remotePart.profile.taxDetails,
        australianTaxInfo: {
          ...remotePart.profile.taxDetails.australianTaxInfo,
          tfn: removeWhiteSpace(remotePart.profile.taxDetails.australianTaxInfo.tfn)
        },
        taxResidentType: remotePart.profile.taxDetails.taxResidentType as TaxResidentType
      },
      employmentDetails: {
        ...remotePart.profile.employmentDetails,
        occupation:
          remotePart.profile.employmentDetails.occupation === OccupationOthersType.Unemployed
            ? OccupationOthersType.Homemaker
            : remotePart.profile.employmentDetails.occupation
      }
    };

    const identity: IdentificationDetails = {
      driversLicence: remotePart.identificationDetails?.driversLicence,
      passport: remotePart.identificationDetails?.passport
    };

    if (remotePart.identificationDetails?.medicare) {
      identity.medicare = {
        ...remotePart.identificationDetails.medicare,
        cardColour: convertMedicareColour(remotePart.identificationDetails.medicare.cardColour as MedicareCardColour)
      };
    }

    const request: OnlineApplicationRequest = {
      messageId: getMessageIdOfSuccessfulIdVerify(
        remotePart.identificationDetails.status,
        remotePart.identificationDetails.identificationType,
        localPart.identityVerifyMessageId
      ),
      accountType: AccountType.Individual,
      email: remotePart.email,
      mobile: remotePart.mobile,
      profile,
      identity,
      termsAndConditions: localPart.termsAndConditions,
      promoCode: localPart.promoCode,
      pepQuestions: remotePart.pepQuestions ? getPEPQuestions(remotePart.pepQuestions) : undefined
    };
    return request;
  },
  convertToRedirectUrl: (paymentMethod: PaymentMethod, accountType: AccountTypeInput) => {
    const { cmcPlatformSite } = createConfig();
    let queryString: string | undefined = '';
    const analyticParamsElement = document.querySelector(`#analytics-params`);
    if (analyticParamsElement) {
      const href = analyticParamsElement.getAttribute(`href`);
      queryString = href?.split('?')[1];
    }
    const promoCode = getPromoCode();
    const promoPath = promoCode ? `offer=${promoCode}&` : '';
    const marginPath = paymentMethod === PaymentMethod.MarginLoan ? MARGIN_ENDPOINT : '';
    return `${cmcPlatformSite}/apply${marginPath}/#/start?${promoPath}type=${accountType}${queryString ? `&${queryString}` : ''}`;
  }
};
